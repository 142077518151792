import Image from 'next/image';
import Link from 'next/link';

import logo from '../../public/images/logo/logo.png';

import Nav from './Nav';
import { useAppContext } from '@/context/Context';
import { useAuth } from '@/context/authContext';
import {
   TbBrandFacebook,
   TbBrandYoutube,
   TbBrandInstagram,
   TbBrandTiktok
} from 'react-icons/tb';

const MobileMenu = () => {
   const { mobile, setMobile } = useAppContext();
   const { authenticated } = useAuth();

   return (
      <>
         <div
            className={`popup-mobile-menu ${mobile ? '' : 'active'}`}
            onClick={() => setMobile(!mobile)}>
            <div className="inner-wrapper">
               <div className="inner-top">
                  <div className="content">
                     <div className="logo">
                        <Link href="/">
                           <Image
                              src={logo}
                              width={137}
                              height={45}
                              alt="Education Logo Images"
                           />
                        </Link>
                     </div>
                     <div className="rbt-btn-close">
                        <button className="close-button rbt-round-btn">
                           <i className="feather-x"></i>
                        </button>
                     </div>
                  </div>
                  <p className="description">
                     Welcome to WOW English Academy!
                     <br />
                     එපා වුන ඉංග්‍රීසි හදවතටම!
                  </p>
                  <ul className="navbar-top-left rbt-information-list justify-content-start">
                     <li>
                        <Link href="mailto:info@wowenglish.lk">
                           <i className="feather-mail"></i>
                           info@wowenglish.lk
                        </Link>
                     </li>
                     <li>
                        <Link href="#">
                           <i className="feather-phone"></i>+94 715 981 736
                        </Link>
                     </li>
                  </ul>
               </div>

               <Nav />

               <div className="mobile-menu-bottom">
                  {!authenticated ? (
                     <div className="rbt-btn-wrapper mb--20">
                        <Link
                           className="rbt-btn border border-primary radius-round btn-sm hover-transform-none w-100 justify-content-center text-center"
                           href="/register">
                           <span>Register</span>
                        </Link>
                     </div>
                  ) : (
                     <div className="rbt-btn-wrapper mb--20">
                        <Link
                           href="/dashboard"
                           className="rbt-btn btn-md hover-icon-reverse w-100 border border-primary">
                           <div className="icon-reverse-wrapper">
                              <span className="btn-text">Dashboard</span>
                              <span className="btn-icon">
                                 <i className="feather-arrow-right"></i>
                              </span>
                              <span className="btn-icon">
                                 <i className="feather-arrow-right"></i>
                              </span>
                           </div>
                        </Link>
                     </div>
                  )}

                  <div className="social-share-wrapper">
                     {/* <span className="rbt-short-title d-block">
                        Find With Us
                     </span> */}
                     <ul className="social-icon social-default transparent-with-border justify-content-start mt--20">
                        <li>
                           <Link href="https://www.facebook.com/WowEnglishAcademy">
                              <TbBrandFacebook />
                           </Link>
                        </li>
                        <li>
                           <Link href="https://www.youtube.com/@wowenglish7680">
                              <TbBrandYoutube />
                           </Link>
                        </li>
                        <li>
                           <Link href="https://www.instagram.com/wow.englishofficial/">
                              <TbBrandInstagram />
                           </Link>
                        </li>
                        <li>
                           <Link href="https://www.tiktok.com/@wowenglishofficial?lang=en">
                              <TbBrandTiktok />
                           </Link>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default MobileMenu;
