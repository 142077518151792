import { useRouter } from 'next/router';
import Link from 'next/link';
import Image from 'next/image';

import { useState } from 'react';

import MenuData from '../../data/MegaMenu';

import CourseLayout from './NavProps/CourseLayout';
import PageLayout from './NavProps/PageLayout';
import ElementsLayout from './NavProps/ElementsLayout';

import addImage from '../../public/images/service/mobile-cat.jpg';

const Nav = () => {
   const [activeMenuItem, setActiveMenuItem] = useState(null);
   const router = useRouter();

   const isActive = href => router.pathname === href;

   const toggleMenuItem = item => {
      setActiveMenuItem(activeMenuItem === item ? null : item);
   };

   return (
      <nav className="mainmenu-nav">
         <ul className="mainmenu">
            <li className="with-megamenu has-menu-child-item position-static">
               <Link
                  className={`${activeMenuItem === 'home' ? 'open' : ''}`}
                  onClick={() => toggleMenuItem('home')}
                  href="/">
                  Home
               </Link>
            </li>

            <li className="with-megamenu has-menu-child-item position-static">
               <Link
                  className={`${activeMenuItem === 'home' ? 'open' : ''}`}
                  onClick={() => toggleMenuItem('home')}
                  href="/about">
                  About Us
               </Link>
            </li>

            <li className="with-megamenu has-menu-child-item position-static">
               <Link
                  className={`${activeMenuItem === 'home' ? 'open' : ''}`}
                  onClick={() => toggleMenuItem('home')}
                  href="/contact">
                  Contact Us
               </Link>
            </li>

            <li className="with-megamenu has-menu-child-item position-static">
               <Link
                  className={`${activeMenuItem === 'home' ? 'open' : ''}`}
                  onClick={() => toggleMenuItem('home')}
                  href="/help">
                  Help & Support
               </Link>
            </li>
         </ul>
      </nav>
   );
};
export default Nav;
