import Link from 'next/link';
import Separator from '../Common/Separator';

const CopyRight = () => {
   return (
      <>
         <Separator />
         <div className="copyright-area copyright-style-1 ptb--20">
            <div className="container">
               <div className="align-items-center">
                  <p className="rbt-link-hover text-center text-lg-start">
                     Copyright © 2024{' '}
                     <Link href="#">WOW English Academy (Pvt) Ltd</Link> All
                     Rights Reserved
                  </p>
               </div>
            </div>
         </div>
      </>
   );
};

export default CopyRight;
