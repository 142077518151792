'use client';

import { Provider } from 'react-redux';
import Separator from '@/components/Common/Separator';
import MobileMenu from '@/components/Header/MobileMenu';
import HeaderStyleTen from '@/components/Header/Header';
import Context from '@/context/Context';
import Store from '@/redux/store';
import Footer from '@/components/Footer/Footer-One';
import { useRouter, usePathname } from 'next/navigation';
import { useEffect } from 'react';
import ReactQueryClientProvider from '@/context/reactQueryContext';

const Home = ({ children }) => {
   const path = usePathname();
   const router = useRouter();

   useEffect(() => {
      let token;

      if (typeof window !== 'undefined') {
         token = localStorage?.getItem('token') || null;
      }
      if (
         !token &&
         path !== '/' &&
         path !== '/contact' &&
         path !== '/register' &&
         path !== '/help' &&
         path !== '/about' &&
         path !== '/login'
      ) {
         router.push('/');
      }
   }, [path]);

   return (
      <>
         <ReactQueryClientProvider>
            <Provider store={Store}>
               <Context>
                  <MobileMenu />
                  <HeaderStyleTen headerSticky="rbt-sticky" headerType="" />
                  <Separator />
                  {children}
                  <div className="mt-5">
                     <Footer />
                  </div>
               </Context>
            </Provider>
         </ReactQueryClientProvider>
      </>
   );
};

export default Home;
