import HeaderComponent from "./Headers/HeaderComponent";

const Header = ({ headerSticky }) => {
  return (
    <>
      <header className="rbt-header rbt-header-10">
        <HeaderComponent
          headerSticky={headerSticky}
          sticky="header-sticky"
          container="container-fluid"
          gapSpaceBetween="header-space-betwween"
          navigationEnd="rbt-navigation-start"
        />
      </header>
    </>
  );
};
export default Header;
