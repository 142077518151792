import authService from '@/services/auth.service';
import toast from 'react-hot-toast';
import axios from 'axios';

const baseURL = process.env.NEXT_PUBLIC_API_URL || 'http://localhost:3000/api';

const useAxios = axios.create({
   baseURL
});

const getUserToken = async () => {
   return localStorage.getItem('token');
};

useAxios.interceptors.request.use(
   async config => {
      const token = await getUserToken();
      if (token) {
         config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
   },
   error => {
      // if unauthorized, logout and redirect to login page
      if (error.response.status === 401) {
         toast.error('Session expired, please login again');
         authService.logout();
      }
      return Promise.reject(error);
   }
);

useAxios.interceptors.response.use(
   response => {
      return response;
   },
   error => {
      // if unauthorized, logout and redirect to login page
      if (error.response.status === 401) {
         toast.error('Session expired, please login again');
         authService.logout();
      }
      return Promise.reject(error);
   }
);

export default useAxios;
