import Axios from '@/hooks/useAxios';
import axios from 'axios';

const baseURL = process.env.NEXT_PUBLIC_API_URL;

const authService = {
   login: async ({ phone, password }) => {
      try {
         const response = await axios.post(baseURL + '/auth/login', {
            phone,
            password
         });
         return response.data;
      } catch (error) {
         throw error;
      }
   },

   register: async ({
      firstName,
      lastName,
      email,
      phone,
      address,
      password
   }) => {
      try {
         const response = await axios.post(baseURL + '/auth/register', {
            firstName,
            lastName,
            email,
            phone,
            address,
            password
         });
         return response.data;
      } catch (error) {
         throw error;
      }
   },

   getUser: async () => {
      try {
         const response = await Axios.get('/auth/me');
         return response.data;
      } catch (error) {
         throw error;
      }
   },

   update: async ({
      firstName,
      lastName,
      email,
      address,
      oldPassword,
      newPassword
   }) => {
      try {
         const response = await Axios.put('/auth/me', {
            firstName,
            lastName,
            email,
            address,
            oldPassword,
            newPassword
         });
         return response.data;
      } catch (error) {
         throw error;
      }
   },

   logout: async () => {
      try {
         localStorage.removeItem('token');
         localStorage.removeItem('user');
         window.location.href = '/';
      } catch (error) {
         throw error;
      }
   }
};

export default authService;
