import Image from 'next/image';
import Link from 'next/link';
import {
   TbBrandFacebook,
   TbBrandYoutube,
   TbBrandInstagram,
   TbBrandTiktok
} from 'react-icons/tb';

import logo from '../../public/images/logo/logo.png';

import CopyRight from './CopyRight';

import FooterData from '../../data/footer.json';
import SingleFooter from './FooterProps/SingleFooter';

const FooterOne = ({ bgColor }) => {
   return (
      <>
         <footer
            className={`rbt-footer footer-style-1 ${
               bgColor ? bgColor : 'bg-color-white'
            } overflow-hidden`}>
            <div className="footer-top">
               <div className="container">
                  {FooterData &&
                     FooterData.footerData.map((footer, index) => (
                        <div className="row g-5" key={index}>
                           <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                              <div className="footer-widget">
                                 <div className="logo">
                                    <Link href="/">
                                       <Image
                                          src={logo}
                                          width={152}
                                          height={50}
                                          alt="Edu-cause"
                                       />
                                    </Link>
                                 </div>

                                 <p className="description mt--20">
                                    {footer.description1}
                                    <br />
                                    {footer.description2}
                                 </p>

                                 <ul className="social-icon social-default justify-content-start">
                                    <li>
                                       <Link href={footer.socialLink[0].link}>
                                          <TbBrandFacebook />
                                       </Link>
                                    </li>
                                    <li>
                                       <Link href={footer.socialLink[1].link}>
                                          <TbBrandYoutube />
                                       </Link>
                                    </li>
                                    <li>
                                       <Link href={footer.socialLink[2].link}>
                                          <TbBrandInstagram />
                                       </Link>
                                    </li>
                                    <li>
                                       <Link href={footer.socialLink[3].link}>
                                          <TbBrandTiktok />
                                       </Link>
                                    </li>
                                 </ul>

                                 <div className="contact-btn mt--30">
                                    <button
                                       className="rbt-btn hover-icon-reverse radius-round border border-primary"
                                       onClick={() =>
                                          window.open(
                                             'https://api.whatsapp.com/send?phone=94718785378&text=Hi',
                                             '_blank'
                                          )
                                       }>
                                       <div className="icon-reverse-wrapper">
                                          <span className="btn-text">
                                             Contact Us
                                          </span>
                                          <span className="btn-icon">
                                             <i className="feather-arrow-right"></i>
                                          </span>
                                          <span className="btn-icon">
                                             <i className="feather-arrow-right"></i>
                                          </span>
                                       </div>
                                    </button>
                                 </div>
                              </div>
                           </div>

                           <SingleFooter
                              classOne="col-lg-3 col-md-6 col-sm-6 col-12 mt--30"
                              title="Useful Links"
                              footerType={footer.usefulLinks}
                           />
                           <div className="col-lg-5 col-md-6 col-sm-6 col-12">
                              <div className="footer-widget">
                                 <h5 className="ft-title">Contact Details</h5>
                                 <ul className="ft-link">
                                    <li>
                                       <div className="row">
                                          <span className="d-inline-block w-auto">
                                             Phone:{' '}
                                          </span>
                                          <div className="col text-start">
                                             <Link href="#">
                                                {footer.phone.num1}
                                             </Link>
                                          </div>
                                       </div>
                                       <div className="row">
                                          <span
                                             className="d-inline-block w-auto"
                                             style={{ opacity: 0 }}>
                                             Phone:{' '}
                                          </span>
                                          <div className="col text-start">
                                             <Link href="#">
                                                {footer.phone.num2}
                                             </Link>
                                          </div>
                                       </div>
                                       <div className="row">
                                          <span
                                             className="d-inline-block w-auto"
                                             style={{ opacity: 0 }}>
                                             Phone:{' '}
                                          </span>
                                          <div className="col text-start">
                                             <Link href="#">
                                                {footer.phone.num3}
                                             </Link>
                                          </div>
                                       </div>
                                    </li>
                                    <li>
                                       <span>E-mail:</span>{' '}
                                       <Link
                                          href="
                                       mailto:info@wowenglish.lk
                                       ">
                                          {footer.mail}
                                       </Link>
                                    </li>
                                    <li>
                                       <span>Address:</span> {footer.address}
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     ))}
               </div>
            </div>

            <CopyRight />
         </footer>
      </>
   );
};

export default FooterOne;
