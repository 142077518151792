import React, {
   createContext,
   useContext,
   useEffect,
   useMemo,
   useState
} from 'react';
import authService from '@/services/auth.service';
import PropTypes from 'prop-types';

const AuthContext = createContext();

const JWT_KEY = 'token';
const USER_DATA_KEY = 'user';

function parseData(data) {
   try {
      return JSON.parse(data);
   } catch (error) {
      console.error('Error in parsing data', error);
      return null;
   }
}

export function useAuth() {
   return useContext(AuthContext);
}

export const AuthProvider = props => {
   const tokenFromStorage =
      typeof window !== 'undefined' && localStorage?.getItem(JWT_KEY);
   const userDataFromStorage =
      typeof window !== 'undefined' && localStorage?.getItem(USER_DATA_KEY);
   const [token, setToken] = useState(tokenFromStorage || null);
   const [user, setUser] = useState(
      token ? parseData(userDataFromStorage) : null
   );
   const [loading, setLoading] = useState(true);
   const authenticated = useMemo(() => !!user, [user]);

   useEffect(() => {
      if (!token) return; // If no JWT, no need to fetch user details
      if (authenticated) return setLoading(false); // If user details already fetched, no need to fetch again

      authService
         .getUser()
         .then(user => {
            setUser(user);
            localStorage.setItem('user', JSON.stringify(user));
         })
         .catch(error => {
            setUser(null);
            console.error('Error in fetching user details', error);
         })
         .finally(() => {
            setLoading(false);
         });
   }, [token, authenticated]);

   const values = {
      user,
      setUser,
      authenticated,
      loading,
      setToken
   };
   return (
      <AuthContext.Provider value={values}>
         {props.children}
      </AuthContext.Provider>
   );
};

AuthProvider.propTypes = {
   children: PropTypes.node.isRequired
};
